import { Box, IconButton, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";
import ActionButtons from "../../../Components/Input/ActionButtons";
import { useEffect, useState } from "react";
import { FormData, emptyFormData } from "../../../Util/FormUtil";
import { Album01Icon, LockKeyIcon, MultiplicationSignIcon, PencilEdit02Icon, ShoppingCart01Icon, UserListIcon, UserLock01Icon } from "@hugeicons/react";
import PaperCollapse from "../../../Components/Widget/PaperCollapse";
import KeyRingItem from "../../../Components/Widget/KeyRingItem";
import Api from "../../../Util/Api";
import { snack } from "../../../Util/Functions";


interface TeamMemberPermissionsProps {
    userId: number;
    userName: string;
    keyRing: string;
    isMobile: boolean;
    onSetKeyRing: any;
    onClose: any;
}

export default function TeamMemberPermissions({ userId, userName, keyRing, isMobile, onSetKeyRing, onClose }: TeamMemberPermissionsProps) {

    const [member, setMember] = useState<FormData>(emptyFormData());
    const keys = keyRing ? keyRing.split(',') : [];

    const config = [
        {
            title: 'Business Configuration',
            icon: <PencilEdit02Icon variant="solid" />,
            items: [
                {
                    key: "nav-brand",
                    label: "Business Area",
                    description: "Show the 'Business' menu item at the top of the screen - this must be enabled for the rest the options in this section to work"
                },
                {
                    key: "brand-config",
                    label: "Edit Business Details",
                    description: "Edit the name, phone number, email address, etc for this business account"
                },
                {
                    key: "brand-defaults",
                    label: "Edit Business Defaults",
                    description: "Edit the default settings for this business account"
                },
                {
                    key: "brand-reports",
                    label: "View Business Reports",
                    description: "See the reports that can be generated for this business account"
                },
                {
                    key: "brand-pages",
                    label: "Edit Business Sites",
                    description: "Edit the pages associated with your business site"
                },
                {
                    key: "brand-markets",
                    label: "Edit Market Areas",
                    description: "Edit the markets that the business serves and who works each market"
                },
                {
                    key: "brand-serviceareas",
                    label: "Edit Service Areas",
                    description: "Edit the service areas that the business works and the travel fees they use"
                },
                {
                    key: "brand-sponsors",
                    label: "Edit Attribution",
                    description: "Edit the list of people that can be given site attribution"
                },
                {
                    key: "brand-designs",
                    label: "Edit Designs",
                    description: "Create and edit site designs"
                },
                {
                    key: "brand-messages",
                    label: "Edit Message Templates",
                    description: "Edit the email messages that send sent to clients"
                },
                {
                    key: "brand-domains",
                    label: "Edit Business Domains",
                    description: "Edit the domains associated with the business"
                },
                {
                    key: "brand-mobileapp",
                    label: "Edit Mobile App",
                    description: "Edit the configuration for the Mobile App"
                },
                {
                    key: "brand-permissions", 
                    label: "Edit Permissions", 
                    description: "Edit the permissions for other team members"
                },
                {
                    key: "brand-apps", 
                    label: "App Integrations", 
                    description: "Connect 3rd-party accounts to your business"
                },
                {
                    key: "brand-api", 
                    label: "API Interfaces", 
                    description: "Use API's to interact with your data"
                },
                {
                    key: "brand-bulkactivate", 
                    label: "Bulk Activations", 
                    description: "Allow access to bulk purchasing of Sites and Deliveries."
                },
                {
                    key: "brand-metadatafield", 
                    label: "Edit Metadata Field", 
                    description: "Edit the generic field on the Business Details page."
                }
            ]
        },
        {
            title: 'Restricted Access',
            icon: <LockKeyIcon variant="solid" />,
            items: [
                {
                    key: "restricted-fees",
                    label: "Product Fees",
                    description: "Allow edit access to Product Fees."
                },
                {
                    key: "restricted-childfees",
                    label: "Child Business Product Fee Edits",
                    description: "Allow editing for Product Fees in Child Businesses."
                },
                {
                    key: "restricted-childsettings",
                    label: "Child Business Settings",
                    description: "Allow editing for Default Settings in Child Businesses."
                },
                {
                    key: "restricted-childdetails",
                    label: "Child Business Details",
                    description: "Allow editing for Business Details in Child Businesses."
                },
                {
                    key: "restricted-childsalestax",
                    label: "Child Business Sales Tax",
                    description: "Allow editing for Sales Tax configuration in Child Businesses."
                }
            ]
        },
        {
            title: 'Team Access',
            icon: <UserLock01Icon variant="solid" />,
            items: [
                {
                    key: "dash-notsimple",
                    label: "Override Simple Mode",
                    description: "Allow Team Members to create new Sites, Clients or Groups, and hide top-level traffic stats and Help Center Videos on the dashboard."
                },
                {
                    key: "order-doneothers",
                    label: "Complete Tasks Assigned To Others",
                    description: "Allow the Team Member to mark any task as complete, including tasks that are assigned to other Team Members."
                },
                {
                    key: "dash-teammode",
                    label: "Team Dashboard",
                    description: "On the Dashboard, see Unscheduled Orders, Incomplete Orders, Assigned Sites to be Completed and the Current Schedule for the entire team"
                },
                {
                    key: "team-login",
                    label: "Team Member Login",
                    description: "Access the accounts of other team members"
                },
                {
                    key: "team-create",
                    label: "Create Team Members",
                    description: "Create new team members for this business account"
                },
                {
                    key: "buycredits",
                    label: "Purchase Credits",
                    description: "Ability to purchase credits for the team to use"
                },
                {
                    key: "buydomains",
                    label: "Purchase Domains",
                    description: "Ability to purchase domains for the team to use"
                },
                {
                    key: "hdchat",
                    label: "HDPH Chat Widget",
                    description: "Enable the HDPH Chat Widget for support access"
                },
                {
                    key: "servicearea-disablebasic",
                    label: "Disable Service Area  Basics",
                    description: "Do not allow this Team Member to edit their existing Service Areas."
                },
                {
                    key: "servicearea-disableview", 
                    label: "Disable Service Area Viewing", 
                    description: "Do not allow this Team Member to view their existing Service Areas."
                },
                {
                    key: "servicearea-enableview", 
                    label: "Enable Service Area Viewing", 
                    description: "Allow this Team Member to view the shape of their existing Service Areas only."
                },
                {
                    key: "servicearea-enablebasic", 
                    label: "Enable Service Area Basics", 
                    description: "Allow this Team Member to view and change the shape of their existing Service Areas only."
                },
                {
                    key: "servicearea-disableadmin", 
                    label: "Disable Service Area Admin", 
                    description: "Do not allow this Team Member to create new/delete Service Areas or edit their Travel Fees."
                },
                {
                    key: "servicearea-enableadmin", 
                    label: "Enable Service Area Admin", 
                    description: "Allow this Team Member to create new/delete Service Areas and edit their Travel Fees (Must also have 'Enable Service Area Basics' enabled)."
                }
            ]
        },
        {
            title: 'Sites',
            icon: <Album01Icon variant="solid" />,
            items: [
                {
                    key: "nolimitsitesteam", 
                    label: "Unlimited Site Access",
                    description: "Search and view all Sites in the system (not only the Sites that are assigned)"
                }
            ]
        },
        {
            title: 'Client Accounts',
            icon: <UserListIcon variant="solid" />,
            items: [
                {
                    key: "nav-clients",
                    label: "Client & Group Areas",
                    description: "Show the 'Clients' and 'Group's menu items at the top of the screen and allow client searches"
                },
                {
                    key: "balance",
                    label: "Manual Balance Adjustments",
                    description: "Give clients account credits or debit a client account"
                }
            ]
        },
        {
            title: 'Shopping Cart',
            icon: <ShoppingCart01Icon variant="solid" />,
            items: [
                {
                    key: "cart",
                    label: "Edit Shopping Cart",
                    description: "Edit products, product categories and price tiers"
                },
                {
                    key: "cart-transfer",
                    label: "Transfer Tasks",
                    description: "Allow tasks assigned to this team member to be reassigned to another team member"
                },
                {
                    key: "cart-modify",
                    label: "Modify/Cancel Orders",
                    description: "Cancel items and refund money on existing orders"
                },
                {
                    key: "cart-payout",
                    label: "Pay Team Members",
                    description: "See earnings queue for other team members and mark earnings as paid"
                },
                {
                    key: "brand-fees",
                    label: "Product Fees",
                    description: "Allow this Team Member to view the Product Fees page"
                },
                {
                    key: "cart-noinvoicing",
                    label: "Disallow Invoicing",
                    description: "Prevent this Team Member from invoicing Shopping Cart orders"
                },
                {
                    key: "order-setfinalized",
                    label: "Set Order Finalized Date",
                    description: "Set the finalize date on open orders."
                },
                {
                    key: "order-editfinalized",
                    label: "Can Edit Finalized Orders",
                    description: "Edit orders that have been finalized."
                }
            ]
        }
    ];

    const saveData = async (updates: any, setUpdates: any) => {
        if (updates.addKeyRing || updates.removeKeyRing) {
            const keyRingData = { add: updates.addKeyRing?.split(','), remove: updates.removeKeyRing?.split(',') };
            const update = await Api.patch('user/' + userId + '/keyring', keyRingData);
            if (update.ok) { 
                var newKeyRing = (keyRing || '').split(',');
                if (keyRingData.add) { newKeyRing = newKeyRing.concat(keyRingData.add); }
                if (keyRingData.remove) { newKeyRing = newKeyRing.filter((v: any) => { return keyRingData.remove.indexOf(v) === -1; }); }
                onSetKeyRing(userId, newKeyRing.join(','));
                if (onClose) { onClose(); }
                snack(T('Update saved.'), { variant: 'success' });
            }
            else {
                snack('Unable save permissions.', { variant: 'error' });
                setUpdates((prev: any) => {
                    prev.saving = false;
                    return {...prev};
                });
            }
        }        
    }

    useEffect(() => {
        if (member.cancel) {
            if (onClose) { onClose(); }
        }
    }, [member.cancel, onClose]);

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column" sx={{ overflow: 'auto' }}>
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T("Permissions")} ({userName})
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>

                {config.map((section, index) => (
                    <PaperCollapse key={index} title={section.title} icon={section.icon} open={false}>
                        {section.items.map((item, index) => (
                            <KeyRingItem 
                                key={index}
                                name={item.key}
                                label={item.label}
                                description={item.description}
                                state={[member, setMember]}
                                defaultChecked={keys.indexOf(item.key) !== -1}
                            />
                        ))}
                    </PaperCollapse>
                ))}
                <Typography variant="body2" sx={{ my: 1 }}><b>NOTE:</b> The Team Member will need to log out and log back in for the new permissions to take effect.</Typography>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, mt: 0, pb: 2 }}>
                <ActionButtons active
                    onSave={saveData} 
                    state={[member, setMember]}
                />
            </Box>
        </Box>
    );
}
