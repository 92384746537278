import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { PlusSignCircleIcon, PencilEdit02Icon, Delete01Icon } from "@hugeicons/react";
import { Box, Button, Chip, Divider, Drawer, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DialogHook, useDialogs } from "@toolpad/core";
import Api from "../../Util/Api";
import ActionButtons from "../../Components/Input/ActionButtons";
import SelectInput from "../../Components/Input/SelectInput";
import SwitchInput from "../../Components/Input/SwitchInput";
import TextInput from "../../Components/Input/TextInput";
import NoAccess from "../../Components/Layout/NoAccess";
import { useLoad } from "../../Util/Hooks";
import { FormData, emptyFormData, errorData } from "../../Util/FormUtil";
import { clipboardSetText, getStates, snack } from "../../Util/Functions";
import { T, D } from "../../Util/Localize";
import PolicyDetails from "./Panels/PolicyDetails";

export default function BusinessSettingsDetails() {
    
    const [data, setData] = useState<any>(null);
    const [policies, setPolicies] = useState<any>(null);
    const [details, setDetails] = useState<FormData>(emptyFormData());
    const [address, setAddress] = useState<FormData>(emptyFormData());
    const [social, setSocial] = useState<FormData>(emptyFormData());
    const [dashboard, setDashboard] = useState<FormData>(emptyFormData());
    const [invoice, setInvoice] = useState<FormData>(emptyFormData());
    const [copyright, setCopyright] = useState<FormData>(emptyFormData());
    const [policyId, setPolicyId] = useState<number | null>(null);
    const isLoading: boolean = (data === null);
    const inputDefaults: any = { data, isLoading };
    const dialogs: DialogHook = useDialogs();
    const props: any = useOutletContext();

    const states = getStates();
    const stateOptions = Object.keys(states).map((s: any) => ([s, s + ' - ' + states[s]]));

    useLoad(() => {
        Api.get('brand').then((r: any) => {
            if (r.ok) { setData(r.data); }
        });
        Api.get('policies').then((r: any) => {
            if (r.ok) { setPolicies(r.data); }
        });
    });
  
    const policyUpdate = (update: any) => {
        let found: boolean = false;
        let newPolicies = policies.map((p: any) => { 
            if (p.pid === update.pid) { found = true; return update; } else { return p; }
        });
        if (!found) { newPolicies.push(update); }
        setPolicies(newPolicies);
    }

    const saveData = async (updates: any, setUpdates: any) => {
        var update = await Api.patch('brand/' + data.brandId, updates);
        if (update.status === 200) { 
            snack(T('Update saved.'), { variant: 'success' });
            setData((prev: any) => ({...prev, ...updates})); 
            setUpdates(emptyFormData());
        }
        else {
            errorData(update, setUpdates);
            snack(T('Unable to update the business details'), { variant: 'error' });
        }
    }

    const handleDelete = async (policy: any) => { 
        if (await dialogs.confirm(T('Are you sure you want to delete the agreement named "[title]"?').replace('[title]', policy.title), { title: T('Delete Agreement') })) {
            const r = await Api.patch('policy/' + policy.pid, { status: -1 })
            if (r.ok) {
                setPolicies((prev: any) => prev.filter((p: any) => p.pid !== policy.pid));
            }
            else {
                snack('Unable to delete Agreement', { variant: 'error' });
            }
        }
    }

    return (Api.user.userType > 2) ? (
        <NoAccess />
    ) : (
        <Box maxWidth="md" sx={{ mx: 'auto' }}>
            <Paper className="content stripe">
                <Typography variant="h6" gutterBottom>
                    {T("Details")}
                </Typography>
                <TextInput {...inputDefaults}
                    name="name"
                    label={T("Business Name")}
                    state={[details, setDetails]}
                    inputProps={{ maxLength: 50 }}
                />
                <Box display="flex" flexWrap="wrap" sx={{ mr: -2 }}>
                    <Box flex={1} flexBasis={100} minWidth={200} sx={{ mr: 2 }} >
                        <TextInput {...inputDefaults}
                            name="phone"
                            label={T("Public Phone Number")}
                            state={[details, setDetails]}
                            inputProps={{ maxLength: 32 }}
                        />
                    </Box>
                    <Box flex={2} flexBasis={200} minWidth={300} sx={{ mr: 2 }} >
                        <TextInput {...inputDefaults}
                            name="email"
                            type="email"
                            label={T("Public Email")}
                            state={[details, setDetails]}
                            inputProps={{ maxLength: 100 }}
                            validate="email"
                        />
                    </Box>
                </Box>
                <ActionButtons 
                    onSave={saveData} 
                    state={[details, setDetails]} 
                />
            </Paper>

            <Paper className="content stripe">
                <Typography variant="h6" gutterBottom>
                    {T("Business Address")}
                </Typography>

                <TextInput {...inputDefaults}
                    name="address"
                    label={T("Street Address")}
                    state={[address, setAddress]}
                    inputProps={{ maxLength: 100 }}
                />
                <TextInput {...inputDefaults}
                    name="address2"
                    label={T("Street Continued")}
                    state={[address, setAddress]}
                    inputProps={{ maxLength: 100 }}
                />
                <Box display="flex" flexWrap="wrap" sx={{ mr: -2 }}>
                    <Box flex={1} minWidth={300} sx={{ mr: 2 }} >
                        <TextInput {...inputDefaults}
                            name="city"
                            label={T("City")}
                            state={[address, setAddress]}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Box>
                    <Box flex={1} minWidth={300} sx={{ mr: 2 }} >
                        <Box display="flex" flexWrap="wrap" sx={{ mr: -2 }}>
                            <Box flex={1} minWidth={120} sx={{ mr: 2 }} >
                                <SelectInput {...inputDefaults}
                                    name="state"
                                    label={T("State")}
                                    state={[address, setAddress]}
                                    options={[['', String.fromCharCode(160)], ...stateOptions]}
                                />
                            </Box>
                            <Box flex={1} minWidth={120} sx={{ mr: 2 }} >
                                <TextInput {...inputDefaults}
                                    name="zip"
                                    label={T("Zip")}
                                    state={[address, setAddress]}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ActionButtons 
                    onSave={saveData} 
                    state={[address, setAddress]} 
                />
            </Paper>

            <Paper className="content stripe">
                <Typography variant="h6" gutterBottom>
                    {T("Social Media")}
                </Typography>
                <TextInput {...inputDefaults}
                    name="linkedIn"
                    label={T("LinkedIn")}
                    state={[social, setSocial]}
                    inputProps={{ maxLength: 256 }}
                    validate="url"
                    />

                <TextInput {...inputDefaults}
                    name="facebook"
                    label={T("Facebook")}
                    state={[social, setSocial]}
                    inputProps={{ maxLength: 256 }}
                    validate="url"
                />
                <TextInput {...inputDefaults}
                    name="twitter"
                    label={T("X")}
                    state={[social, setSocial]}
                    inputProps={{ maxLength: 256 }}
                    validate="url"
                />
                <TextInput {...inputDefaults}
                    name="instagram"
                    label={T("Instagram")}
                    state={[social, setSocial]}
                    inputProps={{ maxLength: 256 }}
                    validate="url"
                />
                <ActionButtons 
                    onSave={saveData} 
                    state={[social, setSocial]} 
                />
            </Paper>

            <Paper className="content stripe">
                <Typography variant="h6" gutterBottom>
                    {T("Client Dashboard")}
                </Typography>
                <TextInput {...inputDefaults}
                    name="welcome"
                    label={T("Welcome message to clients")}
                    state={[dashboard, setDashboard]}
                    multiline
                    maxRows={10}
                    maxLength={5000}
                />
                <TextInput {...inputDefaults}
                    name="orderUrl"
                    label={T("Order Page URL")}
                    state={[dashboard, setDashboard]}
                />
                <ActionButtons 
                    onSave={saveData} 
                    state={[dashboard, setDashboard]}
                />
            </Paper>

            <Paper className="content stripe">
                <Typography variant="h6" gutterBottom>
                    {T("Invoice")}
                </Typography>
                <TextInput {...inputDefaults}
                    name="invoiceMemo"
                    label={T("Note to display on invoices")}
                    state={[invoice, setInvoice]}
                    multiline
                    minRows={2}
                    maxRows={5}
                    maxLength={5000}
                />
                <ActionButtons 
                    onSave={saveData} 
                    state={[invoice, setInvoice]}
                />
            </Paper>

            <Paper className="content stripe">
                <Typography variant="h6" gutterBottom>
                    {T("Copyright License")}
                </Typography>
                <TextInput {...inputDefaults}
                    name="license"
                    label={T("License for use of photography")}
                    state={[copyright, setCopyright]}
                    multiline
                    minRows={2}
                    maxRows={20}
                    maxLength={50000}
                />
                {(!props.isMobile) && (
                    <Box display="flex" flexWrap="wrap">
                        <Typography variant="body2" color="textSecondary">
                            {T("Customize tags:")}
                        </Typography>
                        <Chip label="FullAddress" sx={{ ml: 0.5 }} size="small" onClick={() => { clipboardSetText("[FullAddress]"); }} />
                        <Chip label="Client" sx={{ ml: 0.5 }} size="small" onClick={() => { clipboardSetText("[Client]"); }} />
                        <Chip label="Group" sx={{ ml: 0.5 }} size="small" onClick={() => { clipboardSetText("[Group]"); }} />
                        <Chip label="TeamMember" sx={{ ml: 0.5 }} size="small" onClick={() => { clipboardSetText("[TeamMember]"); }} />
                    </Box>
                )}

                <SwitchInput {...inputDefaults}
                    name="licensePolicy"
                    label={T("Require acknowledgment to download photos")}
                    state={[copyright, setCopyright]}
                    true={1}
                    false={0}
                    sx={{ mt: 2 }}
                />
                <ActionButtons 
                    onSave={saveData} 
                    state={[copyright, setCopyright]}
                />

                {(false) && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="subtitle2" color="textSecondary">
                            Need a License Template?
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Use promo code “HDPH” for special pricing at TheLawTog.
                        </Typography>
                        <Button variant="outlined" color="primary" sx={{ mt: 1 }} onClick={() => { window.open("https://thelawtogshop.com/?sca_ref=4569685.UCzC5G0xaM/", "_blank"); }}>
                            {T("Get Template")}
                        </Button>
                    </>
                )}
            </Paper>

            <Paper className="content stripe">
                {(policies == null) ? (
                    <>
                        <Typography variant="h6">
                            {T("Agreements")}
                        </Typography>
                        <Skeleton variant="rectangular" height={24} sx={{ mt: 2 }} />
                        <Skeleton variant="rectangular" height={24} sx={{ mt: 2 }} />
                        <Skeleton variant="rectangular" height={24} sx={{ mt: 2 }} />
                    </>
                ) : (policies.length === 0) ? (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {T("Agreements")}
                        </Typography>
                        <Box sx={{ p: 3, textAlign: 'center' }}>
                            <Button variant="outlined" onClick={() => { setPolicyId(0); }}>
                                {T(props.isMobile ? "Add your first agreement" : "Add your first team member agreement.")}
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Typography variant="h6" gutterBottom>
                                    {T("Agreements")}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton color="primary" onClick={() => { setPolicyId(0); }}>
                                    <PlusSignCircleIcon variant="solid" />
                                </IconButton>
                            </Box>
                        </Box>
                        {(props.isMobile) ? (
                            <>
                                {policies.map((policy: any) => {
                                    return (
                                        <Box key={policy.pid} sx={{ mt: 2 }}>
                                            <Divider />
                                            <Typography variant="body1" sx={{ mt: 2, wordBreak: 'break-word' }}>
                                                {policy.title}
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" color={(policy.status) ? "success" : "warning"} flex={1}>
                                                    {(policy.status) ? T("Active") : T("Inactive")}
                                                </Typography>
                                                <Typography variant="body2" flex={1}>
                                                    {D(policy.updated, 'SHORT_DATE', true)}
                                                </Typography>
                                                <Box>
                                                    <IconButton color="primary" onClick={() => { setPolicyId(policy.pid); }}>
                                                        <PencilEdit02Icon />
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => { handleDelete(policy); }}>
                                                        <Delete01Icon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </>
                        ) : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{T("Team Member Agreement Name")}</TableCell>
                                        <TableCell>{T("Revision Date")}</TableCell>
                                        <TableCell>{T("Status")}</TableCell>
                                        <TableCell>{T("Actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {policies.map((policy: any) => {
                                        return (
                                            <TableRow key={policy.pid}>
                                                <TableCell>{policy.title}</TableCell>
                                                <TableCell sx={{ wordBreak: 'normal !important' }} title={D(policy.updated, 'DATE_TIME', true)}>{D(policy.updated, 'SHORT_DATE', true)}</TableCell>
                                                <TableCell sx={{ wordBreak: 'normal !important' }}>
                                                    <Typography color={(policy.status) ? "success" : "warning"}>
                                                        {(policy.status) ? T("Active") : T("Inactive")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ wordBreak: 'normal !important' }}>
                                                    <Box display="flex" alignItems="center">
                                                        <IconButton color="primary" onClick={() => { setPolicyId(policy.pid); }}>
                                                            <PencilEdit02Icon />
                                                        </IconButton>
                                                        <IconButton color="primary" onClick={() => { handleDelete(policy); }}>
                                                            <Delete01Icon />
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </>
                )}
            </Paper>
            <Drawer anchor="right" open={policyId !== null} onClose={() => { setPolicyId(null); }}>
                <PolicyDetails policyId={policyId} policyUpdate={policyUpdate} isMobile={props.isMobile} onClose={() => { setPolicyId(null); }} />
            </Drawer>
        </Box>
    );
};
