import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { setTranslations } from './Util/Localize';
import ThemeProvider from './Components/ThemeProvider';
import Http404 from './Pages/http404';
import LayoutComponent from './Components/Layout/LayoutComponent';
import LegacyPage from './Components/Layout/LegacyPage';
import BusinessSettingsDetails from './Pages/Settings/BusinessSettingsDetails';
import BusinessSettingsConfiguration from './Pages/Settings/BusinessSettingsConfiguration';
import BusinessSettingsBranding from './Pages/Settings/BusinessSettingsBranding';
import TeamMembers from './Pages/Team/TeamMembers';
import Products from './Pages/Cart/Products';
import PriceTiers from './Pages/Cart/PriceTiers';
import Upsells from './Pages/Cart/Upsells';
import PromoCodes from './Pages/Cart/PromoCodes';
import WorkflowTasks from './Pages/Cart/WorkflowTasks';
import DetailsQuestions from './Pages/Cart/DetailsQuestions';
import ProductFilters from './Pages/Cart/ProductFilters';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <Http404 />
    },
    {
      path: "/ux",
      element: <LayoutComponent />,
      children: [
        {
          path: "/ux",
          element: <Navigate to="/ux/Dashboard" />
        },
        {
          path: "/ux/Dashboard",
          element: <LegacyPage title="Dashboard" src="/dashboard/default.asp" />
        },
        {
          path: "/ux/Sites",
          element: <LegacyPage title="Sites" src="/sites/search.asp" />
        },
        {
          path: "/ux/Clients",
          element: <LegacyPage title="Clients" src="/clients/search.asp" />
        },
        {
          path: "/ux/Groups",
          element: <LegacyPage title="Groups" src="/groups/search.asp" />
        },
        {
          path: "/ux/Businesses",
          element: <LegacyPage title="Businesses" src="/brand/brands.asp" />
        },
        {
          path: "/ux/Prospects",
          element: <LegacyPage title="Prospects" src="/prospects/" />
        },
        {
          path: "/ux/Groups",
          element: <LegacyPage title="Groups" src="/groups/search.asp" />
        },
        {
          path: "/ux/Business",
          element: <Navigate to="/ux/Ops/Summary" />
        },
        {
          path: "/ux/Ops",
          element: <Navigate to="/ux/Ops/Summary" />
        },
        {
          path: "/ux/Ops/Summary",
          element: <LegacyPage title="Business Summary" src="/brand/default.asp" />
        },
        {
          path: "/ux/Ops/BulkDelivery",
          element: <LegacyPage title="Bulk Delivery" src="/brand/BulkActivation.asp" />
        },
        {
          path: "/ux/Ops/Reports",
          element: <LegacyPage title="Reports" src="/brand/Reports.asp" />
        },
        {
          path: "/ux/Settings/Details",
          element: <BusinessSettingsDetails />
        },
        {
          path: "/ux/Settings/Branding",
          element: <BusinessSettingsBranding />
        },
        {
          path: "/ux/Settings/Config",
          element: <BusinessSettingsConfiguration />
        },
        {
          path: "/ux/Settings/EmailMessages",
          element: <LegacyPage title="Email Messages" src="/brand/Messages.asp" />
        },
        {
          path: "/ux/Settings/Domains",
          element: <LegacyPage title="Domains" src="/brand/Domains.asp" />
        },
        {
          path: "/ux/Settings/MobileApp",
          element: <LegacyPage title="Mobile App" src="/brand/MobileApp.asp" />
        },
        {
          path: "/ux/Settings/AppIntegrations",
          element: <LegacyPage title="Permissions" src="/brand/apps.asp" />
        },
        {
          path: "/ux/Settings/CustomDesigns",
          element: <LegacyPage title="Custom Designs" src="/brand/Designs.asp" />
        },
        {
          path: "/ux/Settings/Pages",
          element: <LegacyPage title="Business Pages" src="/brand/Pages.asp" />
        },
        {
          path: "/ux/Settings/WebhooksAPIs",
          element: <LegacyPage title="Webhooks & APIs" src="/brand/api.asp" />
        },
        {
          path: "/ux/Team/",
          element: <Navigate to="/ux/Team/Members" />
        },
        {
          path: "/ux/Team/Members",
          element: <TeamMembers />
        },
        {
          path: "/ux/Team/PayTeam",
          element: <LegacyPage title="Pay Team" src="/brand/Payout.asp" />
        },
        {
          path: "/ux/Team/MarketAreas",
          element: <LegacyPage title="Market Areas" src="/brand/Markets.asp" />
        },
        {
          path: "/ux/Team/SiteAttribution",
          element: <LegacyPage title="Site Attribution" src="/brand/Sponsors.asp" />
        },
        {
          path: "/ux/Cart",
          element: <Navigate to="/ux/Cart/Products" />
        },
        {
          path: "/ux/Cart/Products",
          element: <Products />
        },
        {
          path: "/ux/Cart/PriceTiers",
          element: <PriceTiers />
        },
        {
          path: "/ux/Cart/Upsells",
          element: <Upsells />
        },
        {
          path: "/ux/Cart/PromoCodes",
          element: <PromoCodes />
        },
        {
          path: "/ux/Cart/WorkflowTasks",
          element: <WorkflowTasks />
        },
        {
          path: "/ux/Cart/DetailQuestions",
          element: <DetailsQuestions />
        },
        {
          path: "/ux/Cart/ProductFilters",
          element: <ProductFilters />
        },
        {
          path: "/ux/Cart/ServiceAreas",
          element: <LegacyPage title="Service Areas" src="/brand/ServiceAreas.asp" />
        },
        {
          path: "/ux/Cart/ProductFees",
          element: <LegacyPage title="Product Fees" src="/brand/Fees.asp" />
        },
        {
          path: "/ux/Cart/SalesTax",
          element: <LegacyPage title="Sales Tax" src="/brand/SalesTax.asp" />
        },
        {
          path: "/ux/Account",
          element: <Navigate to="/ux/Account/Basic" />
        },
        {
          path: "/ux/Account/Basic",
          element: <LegacyPage title="Account Basics" src="/account/default.asp" />
        },
        {
          path: "/ux/Account/Earnings",
          element: <LegacyPage title="Earnings" src="/account/earnings.asp" />
        },
        {
          path: "/ux/Account/ListingPages",
          element: <LegacyPage title="Listing Pages" src="/account/UserSites.asp" />
        },
        {
          path: "/ux/Account/Reports",
          element: <LegacyPage title="Account Reports" src="/account/Reports.asp" />
        },
        {
          path: "/ux/Account/PaymentTypes",
          element: <LegacyPage title="Payment Types" src="/account/PaymentTypes.asp" />
        },
        {
          path: "/ux/Account/Password",
          element: <LegacyPage title="Password" src="/account/UpdatePassword.asp" />
        },
        {
          path: "/ux/Account/AppIntegrations",
          element: <LegacyPage title="App Integrations" src="/account/apps.asp" />
        },
        {
          path: "/ux/Account/ServiceAreas",
          element: <LegacyPage title="Service Areas" src="/account/ServiceAreas.asp" />
        },
        {
          path: "/ux/Support",
          element: <LegacyPage title="Support" src="/support/default.asp" />
        },
        {
          path: "/ux/Admin",
          element: <LegacyPage title="Admin" src="/admin/default.asp" />
        }
      ]
    },
  ], {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true
    }
  })

  setTranslations({ 
    //"Business Name": "BO Name", 
    //"Public Email": { default: "Email", "/ux/Business/Details": "Business Email" } 
  });

  return (
    <ThemeProvider>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </ThemeProvider>
  );
}

export default App;
