import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { MultiplicationSignIcon, MoreVerticalIcon, ViewIcon, ViewOffSlashIcon } from "@hugeicons/react";
import { Box, Button, CircularProgress, IconButton, Paper, Typography } from "@mui/material";
import ActionButtons from "../../../Components/Input/ActionButtons";
import Api from "../../../Util/Api";
import { emptyFormData, FormData } from "../../../Util/FormUtil";
import { snack } from "../../../Util/Functions";
import { useLoad } from "../../../Util/Hooks";
import { T } from "../../../Util/Localize";

interface MarketingKitPageDesignProps {
    onClose: any
}

export default function MarketingKitPageDesign({ onClose }: MarketingKitPageDesignProps) {

    const [data, setData] = useState<any>(null);
    const [updates, setUpdates] = useState<FormData>(emptyFormData());
    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
    const groups = updates.data.grouping ?? data ?? [];

    const onDragEnd = (d: any) => {
        var si = d.source.index;
        var di = d.destination.index;
        if (si === di) { return; }
        setUpdates((prev: any) => {
            var grouping = prev.data.grouping || data;
            var item = grouping[si];
            grouping.splice(si, 1);
            grouping.splice(di, 0, item);
            prev.data.grouping = grouping.map((item: any, i: number) => { item.order = i + 1; return item; });
            prev.modified = true;
            return {...prev};
        });
    }

    const toggleStatus = (gid: number) => {
        setUpdates((prev: any) => {
            var grouping = prev.data.grouping || data;
            prev.data.grouping = grouping.map((item: any) => {
                if (item.groupingId === gid) { item.status = (item.status === 1) ? 0 : 1; }
                return item;
            });
            prev.modified = true;
            return {...prev};
        });
    }

    const saveData = async (updates: any, setUpdates: any) => {
        const grouping = updates.grouping.map((g: any) => { return { groupingId: g.groupingId, status: g.status, order: g.order }; });
        const update = await Api.put('brand/MarketingKitGroupSort', { groupings: grouping });
        if (update.ok) { 
            if (onClose) { onClose(); }
            snack(T('Update saved.'), { variant: 'success' });
        }
        else {
            snack('Unable to update the Marketing Kit prefereces.', { variant: 'error' });
            setUpdates((prev: any) => {
                console.log('prev', prev);
                prev.saving = false;
                return {...prev};
            });
            setStatus('success');
        }
    }

    useLoad(() => {
        Api.get('brand/MarketingKitGroupSort').then((r: any) => {
            console.log(r);
            if (r.ok) { 
                setData(r.data.groupings);
                setStatus('success');
            }
            else {
                snack('Unable to load Marketing Kit Page Design settings', { variant: 'error' });
                setStatus('error');
            }
        });
    });

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column">
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T("Marketing Kit Page Design")}
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>
                <Box flex="1">
                    <Paper className="content">
                        <Typography variant="body1" color="text.primary" gutterBottom>
                            {T("Change the order of the sections by dragging/dropping the order of the sections below.")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {T("Note: If you have Marketing Kit features disabled (Default Settings > Disabled Features) then those sections will already be hidden by default.")}
                        </Typography>
                        {(status === 'loading') ? (
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ my: 4 }}>
                                <CircularProgress color="inherit" size={32} sx={{ opacity: 0.25, mr: 2 }} />
                                <Typography variant="body1" sx={{ opacity: 0.5 }}>{T("Loading...")}</Typography>
                            </Box>
                        ) : (status === 'error') ? (
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ my: 4 }}>
                                <Typography variant="body1" color="error">{T("Failed to load settings.")}</Typography>
                            </Box>
                        ) : (
                            <DragDropContext onDragEnd={(d) => { onDragEnd(d); }}>
                                <Droppable droppableId="groupings" mode="standard">
                                    {(provided) => (
                                        <div {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                            {groups.map((d: any, i: number) => (
                                                <Draggable key={d.groupingId} draggableId={String(d.groupingId)} index={i}>
                                                    {(provided) => (
                                                            <Button 
                                                                fullWidth
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{ background: 'var(--background-default, #fff)', ...provided.draggableProps.style}}
                                                                component="div"
                                                                variant="outlined"
                                                                sx={{ mb: 1, justifyContent: 'flex-start', pl: 0.5, '& .MuiButton-endIcon': { ml: 'auto' }}}
                                                                startIcon={<MoreVerticalIcon size={18} opacity={0.5} />}
                                                                endIcon={<IconButton onClick={() => toggleStatus(d.groupingId)}>{d.status === 1 ? <ViewIcon variant="stroke" size={20} /> : <ViewOffSlashIcon variant="stroke" size={20} />}</IconButton>}
                                                            >
                                                                {d.groupingName}
                                                            </Button>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </Paper>
                </Box>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, pb: 2 }}>
                <ActionButtons active
                    isLoading={status === 'loading'}
                    state={[updates, setUpdates]}
                    onSave={saveData} 
                    onCancel={() => { if (onClose) { onClose(); } }}
                />
            </Box>
        </Box>
    );
}
