import { Box, IconButton, Paper, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";
import ActionButtons from "../../../Components/Input/ActionButtons";
import { useEffect, useState } from "react";
import { FormData, emptyFormData } from "../../../Util/FormUtil";
import { MultiplicationSignIcon } from "@hugeicons/react";

interface TeamMemberProductsPayoutsProps {
    userId: number;
    userName: string;
    isMobile: boolean;
    onClose: any;
}

export default function TeamMemberProductsPayouts({ userId, userName, isMobile, onClose }: TeamMemberProductsPayoutsProps) {

    const [member, setMember] = useState<FormData>(emptyFormData());

    const saveData = async (updates: any, setUpdates: any) => {
    }

    useEffect(() => {
        if (member.cancel) {
            if (onClose) { onClose(); }
        }
    }, [member.cancel, onClose]);

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column">
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T("Products/Payouts")} ({userName})
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>
                <Paper className="content">

                </Paper>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, mt: 0, pb: 2 }}>
                <ActionButtons active
                    onSave={saveData} 
                    state={[member, setMember]}
                />
            </Box>
        </Box>
    );
}
