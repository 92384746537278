import { Avatar, Box, Checkbox, Divider, FormControlLabel, IconButton, Paper, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";
import ActionButtons from "../../../Components/Input/ActionButtons";
import {  useState } from "react";
import { MultiplicationSignIcon } from "@hugeicons/react";
import SelectInput from "../../../Components/Input/SelectInput";
import { mediaSize } from "../../../Util/Functions";

interface AddAuxTeamMemberProps {
    isMobile: boolean;
    onClose: any;
    auxmembers: any;
}

export default function AddAuxTeamMember({ isMobile, onClose, auxmembers }: AddAuxTeamMemberProps) {

    const brands: string[] = auxmembers.map((m: any) => m.brandName).filter((m: any, i: number, a: any) => a.indexOf(m) === i).sort();
    const [brand, setBrand] = useState<string>(brands[0]);

    const saveData = async (updates: any, setUpdates: any) => {
    }

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column" sx={{ overflow: 'auto' }}>
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T("Add a New Auxiliary Team Member")}
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>
                <Paper className="content">
                    <SelectInput
                        name="brand"
                        label={T("Business")}
                        defaultValue={brand}
                        options={brands.sort()}
                        onChange={(e: any) => { setBrand(e.target.value); }}
                    />
                    <Box className="outline" sx={{ mt: 2, p: 8 }}>
                        <Typography variant="body1" gutterBottom>{T("Auxiliary Team Members")}</Typography>
                        {auxmembers.filter((m: any) => m.brandName === brand).map((m: any, i: number) => (
                            <Box key={i}>
                                {i > 0 && <Divider />}
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                        <Box key={i} display="flex" alignItems="center" sx={{ py: 1 }}>
                                            <Avatar
                                                src={"https://media.hd.pics/" + mediaSize(m.media, 100) + "." + m.ext}
                                                alt={(m.firstName + ' ' + m.lastName).trim()}
                                                sx={{ width: 50, height: 50, ml: 1, mr: 2 }}
                                            />
                                            <Typography variant="body1" flex={1}>{(m.firstName + ' ' + m.lastName).trim()}</Typography>
                                        </Box>
                                    }
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Paper>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, mt: 0, pb: 2 }}>
                <ActionButtons active
                    onCancel={onClose}
                    onSave={saveData} 
                    saveLabel={T("Add Auxiliary Member")}
                />
            </Box>
        </Box>
    );
}
